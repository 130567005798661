<template>
  <v-dialog
    v-model="openDialog"
    max-width="1024px"
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>Frage</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="openDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-2">
        <Patcher
          :itemValue="itemData.question"
          itemName="question"
          itemLabel="Frage"
          :hideLabel="false"
          :route="patchRoute + itemData.id"
          inputElement="input"
          inputType="text"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="itemData.question"
          @emit="itemData.question = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="itemData.info"
          itemName="info"
          itemLabel="Beschreibung"
          :hideLabel="false"
          :route="patchRoute + itemData.id"
          inputElement="textarea-md"
          inputType=""
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="itemData.info"
          @emit="itemData.info = $event.value"
        ></Patcher>

        <Answers :questionId="itemData.id" :setId="setId" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import Answers from "@/components/tech/feedbacks/Answers.vue";
export default {
  name: "Question",

  components: {
    Patcher,
    Answers,
  },

  props: ["itemData", "detailDialog", "setId"],

  data() {
    return {
      filePatcherKey: 0,
      patchRoute: `feedbackSets/${this.setId}/questions/`,
      fetchingData: false,
      openDialog: this.detailDialog,
    };
  },

  mixins: [apiRequest, helpers],
};
</script>
