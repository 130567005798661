<template>
  <v-dialog
    v-model="openDialog"
    max-width="1024px"
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>Feedback-Set</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="openDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-2">
        <Patcher
          :itemValue="itemData.name"
          itemName="name"
          itemLabel="Überschrift/Name"
          :hideLabel="false"
          :route="patchRoute + itemData.id"
          inputElement="input"
          inputType="text"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="itemData.name"
          @emit="itemData.name = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="itemData.description"
          itemName="description"
          itemLabel="Einleitung"
          :hideLabel="false"
          :route="patchRoute + itemData.id"
          inputElement="textarea-md"
          inputType=""
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="itemData.description"
          @emit="itemData.description = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="itemData.workingTitle"
          itemName="workingTitle"
          itemLabel="Arbeitstitel"
          :hideLabel="false"
          :route="patchRoute + itemData.id"
          inputElement="input"
          inputType="text"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="itemData.workingTitle"
          @emit="itemData.workingTitle = $event.value"
        ></Patcher>

        <Questions :setId="itemData.isStandard" />

        <Patcher
          :itemValue="itemData.isStandard"
          itemName="isStandard"
          itemLabel="Standard-Set"
          :hideLabel="true"
          :route="patchRoute + itemData.id"
          inputElement="switch"
          inputType=""
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="itemData.isStandard"
          @emit="itemData.isStandard = $event.value"
        ></Patcher>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import Questions from "@/components/tech/feedbacks/Questions.vue";
export default {
  name: "Set",

  components: {
    Patcher,
    Questions,
  },

  props: ["itemData", "detailDialog"],

  data() {
    return {
      filePatcherKey: 0,
      patchRoute: "feedbackSets/",
      fetchingData: false,
      openDialog: this.detailDialog,
    };
  },

  mixins: [apiRequest, helpers],
};
</script>
