<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="12">
        <DataTable
          @tableAction="emittedAction"
          v-bind:items="items"
          v-bind:headers="headers"
          :itemActions="{
            edit: true,
            trash: true,
          }"
          :preloader="preloader"
        />
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          dark
          fab
          bottom
          right
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="createNew"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Neues Set anlegen</span>
    </v-tooltip>

    <Set
      v-if="detailDialog"
      :detailDialog="detailDialog"
      :itemData="itemData"
      :key="componentKey"
    />
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
import Set from "@/components/tech/feedbacks/Set.vue";
export default {
  name: "index",

  components: {
    PageHeader,
    DataTable,
    Set,
  },

  data() {
    return {
      pageTitle: "Feedbacks",
      pageDescription: `<p>Hier werden die Feedback-Sets angelegt und gepflegt.</p>`,
      title: "",
      preloader: false,
      items: [],
      headers: [],
      edit: true,
      trash: true,
      preview: true,
      copy: false,
      search: "",
      dialog: false,
      detailDialog: false,
      componentKey: 0,
      itemData: [],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async deleteItem() {
      const userChoice = await this.$dialog.confirm({
        text: `Soll dieses Set inkl. Fragen und Antworten wirklich gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        let response = await this.deleteRequest(
          "feedbackSets/" + this.currentItemId
        );
        if (response.status === 200) {
          this.items.splice(this.currentItemIndex, 1); // remove from array
        }
      }
    },

    async createNew() {
      /* create new entry */
      let response = await this.postRequest("feedbackSets"); // await data from mixin

      let newItem = response.data.data;
      this.items.splice(0, 0, newItem); // add to array at index 0

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Ein neues Feedback-Set wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    async emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */
        this.currentItemId = value.itemId;
        this.currentItemIndex = value.itemIndex;
        this.deleteItem();
      } else if (value.action === "editItem") {
        this.componentKey += 1;
        this.itemData = value.item;
        this.itemIndex = value.index;
        this.detailDialog = true;
      }
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest("feedbackSets");
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id" },
          { text: "Titel", value: "name" },
          { text: "Arbeitstitel", value: "workingTitle" },
          { text: "Actions", value: "actions", sortable: false },
        ];

        this.items = response.data.data;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
