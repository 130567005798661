<template>
  <v-dialog
    v-model="openDialog"
    max-width="1024px"
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>Antwort</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="openDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-2">
        <Patcher
          :itemValue="itemData.answer"
          itemName="answer"
          itemLabel="Antwort"
          :hideLabel="false"
          :route="patchRoute + itemData.id"
          inputElement="input"
          inputType="text"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="itemData.answer"
          @emit="itemData.answer = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="itemData.evaluation"
          itemName="evaluation"
          itemLabel="Bewertung"
          :route="patchRoute + itemData.id"
          inputElement="select"
          inputType=""
          :selectOptions="[1, 2, 3, 4, 5, 6]"
          :previewValue="itemData.evaluation"
          @emit="itemData.evaluation = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="itemData.color"
          itemName="color"
          itemLabel="Farbe"
          :hideLabel="false"
          :route="patchRoute + itemData.id"
          inputElement="input"
          inputType="text"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="itemData.color"
          @emit="itemData.color = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="itemData.icon"
          itemName="icon"
          itemLabel="Icon"
          :hideLabel="false"
          :route="patchRoute + itemData.id"
          inputElement="input"
          inputType="text"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="itemData.icon"
          @emit="itemData.icon = $event.value"
        ></Patcher>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "Set",

  components: {
    Patcher,
  },

  props: ["itemData", "detailDialog", "setId", "questionId"],

  data() {
    return {
      filePatcherKey: 0,
      patchRoute: `feedbackSets/${this.setId}/questions/${this.questionId}/answers/`,
      fetchingData: false,
      openDialog: this.detailDialog,
    };
  },

  mixins: [apiRequest, helpers],
};
</script>
