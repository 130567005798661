<template>
  <div>
    <v-row class="my-4">
      <v-col cols="12">
        <h2>Fragen</h2>
      </v-col>
      <v-col cols="12">
        <draggable
          class="dragArea list-group"
          :list="items"
          ghost-class="ghost"
          handle=".draghandle"
          @change="changeElement"
          d-flex
          flex-column
        >
          <v-list-item
            v-for="(element, index) in items"
            :key="index"
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
          >
            <v-list-item-title>
              <v-icon left class="draghandle">mdi-menu</v-icon>
              {{ element.question }}
            </v-list-item-title>

            <v-list-item-icon>
              <v-btn
                class="mr-1 white--text"
                fab
                x-small
                color="green"
                elevation="0"
                @click="editItem(element, index)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                class="mx-1"
                fab
                x-small
                color="error"
                elevation="0"
                @click="deleteItem(element.id, index)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </draggable>
      </v-col>
      <v-col cols="12">
        <v-btn dark color="primary" @click="createNew">
          Frage anlegen
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <Question
      v-if="detailDialog"
      :detailDialog="detailDialog"
      :itemData="itemData"
      :setId="setId"
      :key="componentKey"
    />
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import Question from "@/components/tech/feedbacks/Question.vue";
import draggable from "vuedraggable";
export default {
  name: "Questions",

  components: {
    Question,
    draggable,
  },

  props: ["setId"],

  data() {
    return {
      title: "",
      preloader: false,
      items: [],
      headers: [],
      edit: true,
      trash: true,
      preview: true,
      copy: false,
      search: "",
      dialog: false,
      detailDialog: false,
      componentKey: 0,
      itemData: [],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    changeElement: async function (evt) {
      if (evt.moved) {
        /* Element wurde verschoben */
        let newIndex = evt.moved.newIndex;
        let oldIndex = evt.moved.oldIndex;
        let elementId = evt.moved.element.id;

        if (newIndex !== oldIndex) {
          console.log("UPDATE " + elementId + " TO INDEX " + newIndex);

          /* update prio */
          const params = new URLSearchParams();
          params.append("sortOrder", newIndex);
          // patch new prio
          this.preloader = true;
          let response = await this.patchRequest(
            `feedbackSets/${this.setId}/questions/${elementId}/swapSortOrder`,
            params
          );
          this.preloader = false;

          if (response.status === 200) {
            this.$root.snackbarGlobal.snackbar = true;
            this.$root.snackbarGlobal.snackbarText = "Gespeichert!";
            this.$root.snackbarGlobal.snackbarColor = "primary";
            this.$root.snackbarGlobal.snackbarTimeout = 5000;
          }
        }
      }
    },

    async deleteItem(id, index) {
      const userChoice = await this.$dialog.confirm({
        text: `Soll diese Frage inkl. Antworten wirklich gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        let response = await this.deleteRequest(
          `feedbackSets/${this.setId}/questions/${id}`
        );
        if (response.status === 200) {
          this.items.splice(index, 1); // remove from array

          /* start snackbar */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Der Datensatz wurde erfolgreich gelöscht.";
          this.$root.snackbarGlobal.snackbarColor = "green";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      }
    },

    async createNew() {
      /* create new entry */
      let response = await this.postRequest(
        `feedbackSets/${this.setId}/questions`
      ); // await data from mixin

      let newItem = response.data.data;
      this.items.splice(0, 0, newItem); // add to array at index 0

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Ein neues Feedback-Set wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    editItem(element, index) {
      this.componentKey += 1;
      this.itemData = element;
      this.itemIndex = index;
      this.detailDialog = true;
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        `feedbackSets/${this.setId}/questions`
      );
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id" },
          { text: "Frage", value: "question" },
          { text: "Actions", value: "actions", sortable: false },
        ];

        this.items = response.data.data;
      }
    },
  },

  mixins: [apiRequest],
};
</script>

<style scoped>
.draghandle {
  cursor: grab;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb !important;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
